<template>
  <div class="rtc-container">
<!--    <p v-if="isHostMode" class="label">操作</p>-->

    <div class="control-container">
      <!-- 左侧按钮容器 -->
      <div class="left-control-container">
        <el-button
            class="button"
            type="primary"
            size="small"
            style="text-align: center; font-size: 25px"
            v-if="this.join_state === 0"
            @click="handleJoinRoom" :disabled="isDisabled">进入房间</el-button>
        <el-button
            class="button"
            type="primary"
            size="small"
            style="text-align: center; font-size: 25px"
            v-if="this.join_state === 1"
            @click="handleLeave">离开房间</el-button>
        <el-button
            class="button"
            type="primary"
            size="small"
            v-if="isInviteMode &&this.share_state === 0"
            style="display: none; font-size: 25px"
            @click="handleStartScreenShare">开始共享屏幕</el-button>
        <el-button
            class="button"
            type="primary"
            size="small"
            v-if="isInviteMode &&this.share_state === 1"
            style="display: none; font-size: 25px"
            @click="handleStopScreenShare">停止共享屏幕</el-button>
        <el-button
            v-if="isHostMode && this.public_state === 0"
            class="button"
            type="info"
            size="small"
            @click="handlePublish"
            style="display: none; font-size: 25px">发布流</el-button>
        <el-button
            v-if="isHostMode && this.public_state === 1"
            class="button"
            type="info"
            size="small"
            @click="handleUnpublish"
            style="display: none; font-size: 25px">取消发布流</el-button>

      </div>

      <!-- 右侧按钮容器 -->
      <div class="right-control-container">
        <el-button
            class="button"
            type="primary"
            size="small"
            style="text-align: center; font-size: 25px"
            v-if="isHostMode && this.join_state === 1"
            @click="dialogVisible = true">发送邀请</el-button>

        <el-popover v-if="isInviteMode" placement="right" trigger="click" width="auto">
          <div style="text-align: center;font-size: 20px">请选择查询项目</div>
          <el-button @click="NewGetECG">
            静态心电
          </el-button>
<!--          <el-button @click="NewGetECGHolter">-->
<!--            动态心电-->
<!--          </el-button>-->
          <el-button @click="NewOtherFile">
            综合体检报告
          </el-button>
          <el-button v-if="isInviteMode" slot="reference" type="primary" size="small" style="text-align: center; font-size: 25px">查看报告</el-button>
        </el-popover>
      </div>

      <div v-if="isHostMode" class="screen-share-control-container">
      </div>

    </div>


    <!-- 显示邀请链接 -->
    <!--    邀请链接部分，目前为随机生成邀请页面，有快捷复制按钮，暂时考虑使用快捷复制按钮兼职向后台上传邀请链接的功能-->
<!--    <div v-if="showInviteLink" class="invite-link-container">-->
<!--      <span>复制链接邀请好友加入视频通话，一条链接仅可邀请一人，复制后自动更新链接。</span>-->
<!--      <el-input class="invite-input" v-model="inviteLink">-->
<!--        <template slot="prepend">-->
<!--          <el-tooltip-->
<!--              :visibleArrow="false"-->
<!--              effect="dark"-->
<!--              content="Copied!"-->
<!--              placement="bottom"-->
<!--              :manual="true"-->
<!--              v-model="showCopiedTip">-->
<!--            &lt;!&ndash;            <span class="invite-btn" @click="handleCopyInviteLink">&ndash;&gt;-->
<!--            &lt;!&ndash;&lt;!&ndash;              <svg-icon icon-name="copy"></svg-icon>&ndash;&gt;&ndash;&gt;-->
<!--            &lt;!&ndash;              <el-button>复制链接</el-button>&ndash;&gt;-->
<!--            &lt;!&ndash;            </span>&ndash;&gt;-->
<!--            <span-->
<!--                data-clipboard-action="copy"-->
<!--                class="invite-btn"-->
<!--                :data-clipboard-text="this.inviteLink"-->
<!--                @click="copyLink">-->
<!--              手动复制链接-->
<!--            </span>-->
<!--          </el-tooltip>-->
<!--        </template>-->
<!--      </el-input>-->
<!--    </div>-->

    <div class="invite-link-container" v-if="showInviteLink">
      <span>复制链接邀请好友加入视频通话，一条链接仅可邀请一人，复制后自动更新链接。</span>
      <el-input class="invite-input" v-model="inviteLink">
        <template slot="prepend">
          <el-tooltip
              :visibleArrow="false"
              effect="dark"
              content="Copied!"
              placement="bottom"
              :manual="true"
              v-model="showCopiedTip">
            <span
                data-clipboard-action="copy"
                class="invite-btn"
                :data-clipboard-text="this.inviteLink"
                @click="copyLink">
              手动复制链接
            </span>
          </el-tooltip>
        </template>
      </el-input>
    </div>

    <!--    <el-form ref="form" :model="form" label-width="80px">-->
    <!--      <el-form-item label="选择医生">-->
    <!--        <el-select v-model="selectValue" placeholder="请选择">-->
    <!--          <el-option-->
    <!--              v-for="item in docInfo"-->
    <!--              :key="item.docId"-->
    <!--              :label="item.realName"-->
    <!--              :value="item.docId">-->
    <!--          </el-option>-->
    <!--        </el-select>-->
    <!--      </el-form-item>-->

    <!--      <el-form-item>-->
    <!--        <el-button type="primary" @click="DoInvite">立即发送</el-button>-->
    <!--        <el-button>取消</el-button>-->
    <!--      </el-form-item>-->
    <!--    </el-form>-->

    <el-dialog title="邀请医生" :visible.sync="dialogVisible" class="custom-dialog" width="80%">
      <el-select v-model="selectValue" value-key="docId" placeholder="请选择">
        <el-option
            v-for="item in docInfo"
            :key="item.docId"
            :label="item.realName"
            :value="item">
        </el-option>
      </el-select>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click=DoInvite>确 定</el-button>
      </span>
    </el-dialog>

    <div class="NewContainer" v-if="isInviteMode">
      <el-col>
        <el-row v-if="isInviteMode">
          <el-col span="12">
            <div class="remote-container">
              <div
                  v-for="(item) in remoteStreamList"
                  :key="item.getUserId()"
                  :id="item.getUserId()"
                  class="remote-stream-container"
                  @click="fullScreen(item.getUserId())">
                <!--              <div>单击视频窗口开启或关闭全屏</div>-->
              </div>
            </div>
          </el-col>
          <el-col span="12">
            <!-- 本地流区域 -->
            <!--        副视频窗，显示自己画面，横向占位固定。包括静音和关闭摄像头功能。-->
            <div v-if="localStream" class="local-stream-container">
              <!-- 本地流播放区域 -->
              <div id="localStream" class="local-stream-content"></div>
              <!-- 本地流操作栏 -->
              <div v-if="isPlayingLocalStream" class="local-stream-control">
                <div class="video-control control" style="display: none">
            <span v-if="!isMutedVideo" @click="unmuteVideo" style="display: none">
              <i class="el-icon-video-pause" style="background-color: #ffffff"></i>
            </span>
                  <span v-if="isMutedVideo"  @click="muteVideo" style="display: none">
                  <i class="el-icon-video-play" style="background-color: #ffffff"></i>
            </span>
                </div>
                <div class="audio-control control" style="display: none">
            <span v-if="!isMutedAudio" @click="muteAudio" style="display: none">
              <i class="el-icon-turn-off-microphone" style="background-color: #ffffff"></i>
            </span>
                  <span v-if="isMutedAudio" @click="unmuteAudio" style="display: none">
                  <i class="el-icon-microphone" style="background-color: #ffffff"></i>
            </span>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>

        <el-row v-if="isInviteMode">
          <el-col span="24">
            <AcrossTemplate ref="GoAcross"></AcrossTemplate>
          </el-col>
        </el-row>
      </el-col>
    </div>

<!--    <div class="HostContainer" v-if="isHostMode">-->
<!--      <el-col>-->
<!--        <el-row v-if="isHostMode">-->
<!--          <el-col span="12">-->

<!--            <div class="remote-container">-->
<!--              <div-->
<!--                  v-for="(item) in remoteStreamList"-->
<!--                  :key="item.getUserId()"-->
<!--                  :id="item.getUserId()"-->
<!--                  class="remote-stream-container"-->
<!--                  @click="fullScreen(item.getUserId())">-->
<!--&lt;!&ndash;                <div>单击视频窗口开启或关闭全屏</div>&ndash;&gt;-->
<!--              </div>-->
<!--            </div>-->
<!--          </el-col>-->

<!--          <el-col span="12">-->
<!--            <div v-if="localStream" class="local-stream-container">-->
<!--              &lt;!&ndash; 本地流播放区域 &ndash;&gt;-->
<!--              <div id="localStream" class="local-stream-content"></div>-->
<!--            </div>-->
<!--          </el-col>-->

<!--        </el-row>-->
<!--      </el-col>-->
<!--    </div>-->

    <div class="HostContainer" v-if="isHostMode">
      <div v-if="remoteStreamList.length" class="remote-container">
        <div
            v-for="(item) in remoteStreamList"
            :key="item.getUserId()"
            :id="item.getUserId()"
            class="remote-stream-container"
            @click="fullScreen(item.getUserId())">
          <!-- Single remote stream -->
        </div>
        <div v-if="localStream" class="local-stream-container">
          <div id="localStream" class="local-stream-content"></div>
        </div>
      </div>
      <div v-else class="local-only-container">
        <div v-if="localStream" class="local-stream-container-alone">
          <div id="localStream" class="local-stream-content"></div>
        </div>
      </div>
    </div>



<!--    <el-col v-if="isHostMode">-->
<!--      &lt;!&ndash;远端流&ndash;&gt;-->
<!--      <el-row :span="10" style="height: 70%">-->
<!--        <div class="remote-container">-->
<!--          <div-->
<!--              v-for="(item) in remoteStreamList"-->
<!--              :key="item.getUserId()"-->
<!--              :id="item.getUserId()"-->
<!--              class="remote-stream-container"-->
<!--              @click="fullScreen(item.getUserId())">-->
<!--            <div>单击视频窗口开启或关闭全屏</div>-->
<!--          </div>-->
<!--        </div>-->

<!--      </el-row>-->

<!--      <el-row :span="2" style="height: 20%">-->
<!--        <div v-if="localStream" class="local-stream-container">-->
<!--          &lt;!&ndash; 本地流播放区域 &ndash;&gt;-->
<!--          <div id="localStream" class="local-stream-content"></div>-->
<!--        </div>-->
<!--      </el-row>-->
<!--    </el-col>-->

  </div>
</template>

<script>
import rtc from './mixins/rtc.js';
import shareRtc from  './mixins/share-rtc.js';
import LibGenerateTestUserSig from '@/utils/lib-generate-test-usersig.min.js';
import Cookies from 'js-cookie';
import screenfull from 'screenfull';
import AcrossTemplate from "@/views/common/across_second.vue";
// import VueClipboards from 'vue-clipboards';
import Vue from "vue";
// Vue.use(VueClipboards);
import clipboard from 'clipboard';
//注册到vue原型上
Vue.prototype.clipboard = clipboard;

export default {
  name: "user_room",
  components: {
    AcrossTemplate,
  },
  mixins: [rtc, shareRtc],
  props: {
    type: String,
    sdkAppId: Number,
    secretKey: String,
    userId: String,
    roomId: Number,
    cameraId: String,
    microphoneId: String,
    inviteUserSig: String,
  },

  data() {
    return {
      isSwapped: false,
      APIID: 'VM20386927',
      APIKEY: '927fe2737d91eec5157808722e76f5aa',
      join_state: 0,
      public_state: 0,
      share_state: 0,
      inviteLink: '',
      showCopiedTip: false,
      UserId: '',
      reportList: [],
      logList: [],
      docInfo: [],
      form: {
        DocId: '',
        InviteLink: '',
      },
      selectValue:'',
      dialogVisible: false,
      isDisabled:false,//答卷提交按钮是否生效
    }
  },

  computed: {
    isHostMode() {
      return this.type !== 'invite';
    },
    isInviteMode() {
      return this.type === 'invite';
    },
    showInviteLink() {
      return this.isHostMode && (this.isJoined || this.isShareJoined) && this.inviteLink;
    },
    crossPlatformStatus: {
      get() { return this.$store.state.module.crossPlatform },
      set(val) { this.$store.commit('module/updateCrossPlatform', val) }
    },

  },

  watch: {
    cameraId(val) {
      this.switchDevice('video', val);
    },
    microphoneId(val) {
      this.switchDevice('audio', val);
    },
  },

  methods: {
    swapStreams() {
      this.isSwapped = !this.isSwapped;
    },
    // 点击【Join Room】按钮
    async handleJoinRoom() {
      this.isDisabled = true
      if (this.isHostMode) {
        if (!this.sdkAppId || !this.secretKey) {
          alert("请输入 sdkAppId 和 secretKey");
          return;
        }
        if (!this.userId || !this.roomId) {
          alert("请输入 userId 和 roomId");
          return;
        }
        const userSigGenerator = new LibGenerateTestUserSig(this.sdkAppId, this.secretKey, 604800);
        this.userSig = userSigGenerator.genTestUserSig(this.userId);
      } else {
        if (!this.sdkAppId || !this.inviteUserSig || !this.userId || !this.roomId) {
          alert("请重新获取邀请链接！");
          return;
        }
        this.userSig = this.inviteUserSig;
      }
      await this.initClient();
      await this.join();
      await this.initLocalStream();
      await this.playLocalStream();
      await this.publish();
      this.generateInviteLink();
      this.join_state = 1;

      // this.getCanOrder()
    },

    // 点击【Leave Room】按钮
    async handleLeave() {
      await this.leave();
      // Cookies.set('Opened',0,{path:"/"})
      // this.submitResult();
      this.join_state = 0;
      this.isDisabled = false
    },

    // 点击【开始屏幕分享】按钮
    async handleStartScreenShare() {
      // Cookies.set('Shared',1,{path:"/"})
      if (!this.sdkAppId || !this.secretKey) {
        alert("请输入 sdkAppId 和 secretKey");
        console.log(this.sdkAppId)
        console.log(this.secretKey)
        return;
      }
      await this.initShareClient();
      await this.initShareLocalStream();
      await this.handleShareJoin();
      await this.handleSharePublish();
      this.generateInviteLink();
      this.share_state = 1;
    },

    // 点击【停止屏幕分享按钮】
    async handleStopScreenShare() {
      // Cookies.set('Shared',0,{path:"/"})
      await this.handleShareUnpublish();
      await this.handleShareLeave();
      this.share_state = 0;
    },

    // 点击【Publish】按钮
    async handlePublish() {
      await this.publish();
      this.public_state = 1;
    },

    // 点击【Unpublish】按钮
    async handleUnpublish() {
      await this.unPublish();
      this.public_state = 0;
    },

    NewOtherFile(){
      Cookies.set('Treating',2,{path:"/"})
      this.$refs.GoAcross.goOtherFile()
    },
    NewGetECG(){
      Cookies.set('Treating',2,{path:"/"})
      this.$refs.GoAcross.goECG();
    },
    NewGetECGHolter(){
      Cookies.set('Treating',2,{path:"/"})
      this.$refs.GoAcross.goECGHolter();
    },

    generateInviteLink() {
      if (!this.isHostMode) {
        return;
      }
      // const { sdkAppId, secretKey, roomId } = this;
      // const inviteUserId = `user_${parseInt(Math.random() * 100000000, 10)}`;
      // const userSigGenerator = new LibGenerateTestUserSig(sdkAppId, secretKey, 604800);
      const sdkAppId = 1400679197;
      const secretKey = 'eb0260efee2dd0afe1351537ecec24caedc71fc0ff1a83b66dbb62d5db15a32c';
      // const inviteUserId = this.UserId + 10086;
      const inviteUserId =  `user_${parseInt(Math.random() * 100000000, 10)}`;
      const roomId = this.UserId;
      const userSigGenerator = new LibGenerateTestUserSig(sdkAppId, secretKey, 604800);
      const inviteUserSig = userSigGenerator.genTestUserSig(inviteUserId);

      this.inviteLink = encodeURI(`${location.origin}${location.pathname}#/across/TreatInviteAcross?sdkAppId=${sdkAppId}&userSig=${inviteUserSig}&roomId=${roomId}&userId=${inviteUserId}`);
    },

    copyLink(){
      var that = this;
      let clipboard = new that.clipboard(".invite-btn");
      clipboard.on('success', function () {
        that.showCopiedTip = true;
        setTimeout(() => {
          that.showCopiedTip = false;
        }, 1500);
      });
      this.generateInviteLink();
      clipboard.on('error', function () {
        console.log("寄！！！")
      });
    },
    handleCopyInviteLink() {
      navigator.clipboard.writeText(this.inviteLink);
      this.showCopiedTip = true;
      setTimeout(() => {
        this.showCopiedTip = false;
      }, 1500);
      this.generateInviteLink();
    },

    fullScreen (e) {
      const locEl = document.getElementById(e);
      // 先使用screenfull.enabled方法判断是否支持screenfull
      // 如果不允许进入全屏，发出不允许提示 浏览器不能全屏
      if(!screenfull.isEnabled){
        this.$message({
          message:'浏览器不能全屏，安装其他版本试试',
          type:'warning'
        })
        return false
      }
      // 调用 screenfull.toggle() 可以双向切换全屏与非全屏
      screenfull.toggle(locEl)
      this.$message({
        message: '已全屏',
        type: 'success'
      })
    },

    DoPhone(){
      console.log("语音通知")
      this.$http({
        url: "https://www.xin-gou.com/ihuyi/webservice/voice.php?method=Submit&account="+this.APIID+"&password="+this.APIKEY+"&mobile="+this.selectValue.phone+"&content=心狗健康提醒您，您有一条呼叫消息，请及时查看"
      }).then(({ data }) => {
        if (data && data.code === 2) {
          console.log("看看收到电话")
          console.log(data)
          // this.invite_state = 1
          this.$notify({
            title: '成功',
            message: '发送邀请成功',
            type: 'success'
          })
        } else {
          // this.$message.error(data.msg)
          console.log("寄！！！")
          console.log(data)
        }
      })
    },

    DoInvite(){
      console.log("看看上传uid：" + this.UserId)
      console.log("看看上传did：" + this.selectValue.docId)
      console.log("看看上传IL：" + this.inviteLink)
      console.log("看看上传手机：" + this.selectValue.phone)
      this.$http({
        url: this.$http.adornUrl("/doctor/recordorder/app/quickDiagnosisSend"),
        method: 'post',
        data: this.$http.adornData({
          userId: this.UserId,
          docId: this.selectValue.docId,
          dateTime: "2000-12-12 18:00",
          // uname: '李志远',
          inviteLink: this.inviteLink,
        })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log("看看收到的东西")
          console.log(data)
          // this.invite_state = 1
          this.$notify({
            title: '成功',
            message: '发送邀请成功',
            type: 'success'
          })
        } else {
          this.$message.error(data.msg)
        }
      })

      this.dialogVisible = false
      this.DoPhone()
    },

    getECGdata() {
      if (this.isInviteMode) {
        this.$http({
          url: this.$http.adornUrl("/doctor/personalInfo/staticecg/list"),
          method: "get",
          params: this.$http.adornParams({ uid: this.UserId }),
        }).then(({ data }) => {
          if (data && data.code === 0) {
            console.log("看看静态单导列表");
            console.log(data)
            this.reportList = data.staticEcgEntities;
          } else {
            // this.getCaptcha()
            this.$message.error(data.msg);
          }
        });
      }
    },

    getCanOrder(){
      if (this.isHostMode) {
        console.log("看看2024年5月15日");
        // console.log(Cookies.get('PID'));
        console.log(this.UserId);
        this.crossPlatformStatus = true
        let uid = this.UserId
        this.$http({
          url: this.$http.adornUrl("/enterprise/enterprisedocrel/app/getDoctorByUid?uid="+uid),
          method: "post",
          // params: this.$http.adornParams({ uid: this.UserId }),
        }).then(({ data }) => {
          if (data && data.code === 0) {
            console.log("看看医生列表");
            console.log(data)
            // this.reportList = data.staticEcgEntities;
            this.docInfo = data.list
            console.log("看看医生数据")
            console.log(this.docInfo)
          } else {
            // this.getCaptcha()
            this.$message.error(data.msg);
          }
        });
      }


    },

    getECG(){
      console.log("看看2023年1月8日");
      console.log(Cookies.get('PID'));
      this.crossPlatformStatus = true
      let uid = this.UserId
      this.$router.push({ name: 'HistorySingoJump',
        query: {
          uid: uid,
        } })
    },
    getECGHolter(){
      console.log("看看2023年1月8日");
      console.log(Cookies.get('PID'));
      this.crossPlatformStatus = true
      let uid = this.UserId

      this.$router.push({ name: 'HistorySingoHolterJump',
        query: {
          uid: uid,
        } })
    },

    // 显示成功的 Log
    addSuccessLog(log) {
      if (!this.isHostMode) {
        return;
      }
      this.logList.push({
        type: 'success',
        log,
      });

      // const { scrollHeight } = this.$refs.logContainer;
      const { scrollHeight } = 350;

      console.log("看看高度")
      console.log(scrollHeight)

      // this.$refs.logContainer.scrollTop = scrollHeight;
      // this.$refs.logContainer.scrollTop = 350;
    },

    // 显示失败的 Log
    addFailedLog(log) {
      if (!this.isHostMode) {
        return;
      }
      this.logList.push({
        type: 'failed',
        log,
      });
      // const { scrollHeight } = this.$refs.logContainer;
      const { scrollHeight } = 350;
      console.log("看看高度")
      console.log(scrollHeight)

      // this.$refs.logContainer.scrollTop = scrollHeight;
      // this.$refs.logContainer.scrollTop = 350;
    },
    reportSuccessEvent(name) {
      this.$aegis.reportEvent({
        name,
        ext1: `${name}-success`,
        ext2: 'webrtcQuickDemoVue2',
        ext3: this.sdkAppId,
      });
    },
    reportFailedEvent(name, error, type = 'rtc') {
      this.$aegis.reportEvent({
        name,
        ext1: `${name}-failed#${this.roomId}*${type === 'share' ? this.shareUserId : this.userId}*${error.message}`,
        ext2: 'webrtcQuickDemoVue2',
        ext3: this.sdkAppId,
      });
    },

  },

  created() {

    // this.getECGdata();
    this.UserId = this.$route.query.UserId
    this.getCanOrder()
    // this.sdkAppId = 1400679197;
    // this.secretKey = 'eb0260efee2dd0afe1351537ecec24caedc71fc0ff1a83b66dbb62d5db15a32c';
    console.log('看看初始化')
    console.log(this.sdkAppId)
    console.log(this.secretKey)
  },


}
</script>

<!--<style lang="scss" scoped>-->
<!--.rtc-container {-->
<!--  position: relative; /* 为了使子元素的绝对定位基于这个容器 */-->

<!--  .label {-->
<!--    margin: 1px 0 1px;-->
<!--    text-align: left;-->
<!--    font-weight: bold;-->
<!--  }-->

<!--  .control-container {-->
<!--    position: relative;-->
<!--    margin-bottom: 1px;-->
<!--    height: 40px; /* 确保有足够的高度容纳按钮 */-->

<!--    .left-control-container {-->
<!--      position: absolute;-->
<!--      left: 5px;-->
<!--      top: 0;-->
<!--      display: flex;-->
<!--      align-items: center;-->
<!--      height: 100%;-->
<!--    }-->

<!--    .right-control-container {-->
<!--      position: absolute;-->
<!--      right: 5px;-->
<!--      top: 0;-->
<!--      display: flex;-->
<!--      align-items: center;-->
<!--      height: 100%;-->
<!--    }-->
<!--  }-->

<!--  .invite-link-container {-->
<!--    width: 95%;-->
<!--    color: #084298;-->
<!--    background-color: #cfe2ff;-->
<!--    position: relative;-->
<!--    padding: 10px 16px;-->
<!--    margin-bottom: 10px;-->
<!--    margin-top: 10px;-->
<!--    border: 1px solid #b6d4fe;-->
<!--    border-radius: 0.25rem;-->
<!--    .invite-input {-->
<!--      margin-top: 10px;-->
<!--    }-->
<!--    .invite-btn {-->
<!--      display: flex;-->
<!--      cursor: pointer;-->
<!--    }-->
<!--  }-->

<!--  .NewContainer {-->
<!--    margin-top: 5px;-->
<!--    width: 100%;-->
<!--    display: flex;-->
<!--    justify-content: space-between;-->
<!--    .log-container {-->
<!--      flex-grow: 1;-->
<!--      border: 1px solid #dddddd;-->
<!--      height: 360px;-->
<!--      padding: 10px;-->
<!--      margin-right: 16px;-->
<!--      overflow-y: scroll;-->
<!--      .log-label {-->
<!--        margin: 0 0 6px;-->
<!--        font-weight: bold;-->
<!--      }-->
<!--      .log-state {-->
<!--        display: inline-block;-->
<!--        margin-right: 6px;-->
<!--      }-->
<!--      > div {-->
<!--        font-size: 12px;-->
<!--      }-->
<!--    }-->
<!--    .local-stream-container {-->
<!--      width: 100%;-->
<!--      height: 100%;-->
<!--      position: relative;-->
<!--      margin-left: 0;-->
<!--      flex-shrink: 0;-->
<!--      .local-stream-content {-->
<!--        width: 100%;-->
<!--        height: 100%;-->
<!--      }-->
<!--      .local-stream-control {-->
<!--        width: 100%;-->
<!--        height: 30px;-->
<!--        position: absolute;-->
<!--        bottom: 0;-->
<!--        background-color: rgba(0, 0, 0, 0.3);-->
<!--        display: flex;-->
<!--        justify-content: flex-end;-->
<!--        align-items: center;-->
<!--        padding: 0 10px;-->
<!--        .control {-->
<!--          margin-left: 10px;-->
<!--        }-->
<!--        .icon-class {-->
<!--          color: #fff;-->
<!--          cursor: pointer;-->
<!--          width: 20px;-->
<!--          height: 20px;-->
<!--        }-->
<!--      }-->
<!--    }-->
<!--  }-->

<!--  //.HostContainer {-->
<!--  //  //position: relative;-->
<!--  //  //display: flex;-->
<!--  //  //justify-content: center;-->
<!--  //  //align-items: center;-->
<!--  //  //height: 60vh; /* Adjust as needed */-->
<!--  //}-->

<!--  .HostContainer {-->
<!--    //height: 60vh;-->
<!--    position: relative;-->
<!--    margin-top: 5px;-->
<!--    width: 100%;-->
<!--    display: flex;-->
<!--    align-items: center;-->
<!--    justify-content: space-between;-->
<!--    //justify-content: center;-->
<!--    .log-container {-->
<!--      flex-grow: 1;-->
<!--      border: 1px solid #dddddd;-->
<!--      height: 360px;-->
<!--      padding: 10px;-->
<!--      margin-right: 16px;-->
<!--      overflow-y: scroll;-->
<!--      .log-label {-->
<!--        margin: 0 0 6px;-->
<!--        font-weight: bold;-->
<!--      }-->
<!--      .log-state {-->
<!--        display: inline-block;-->
<!--        margin-right: 6px;-->
<!--      }-->
<!--      > div {-->
<!--        font-size: 12px;-->
<!--      }-->
<!--    }-->
<!--    .local-stream-container {-->
<!--      width: 100%;-->
<!--      height: 100%;-->
<!--      position: relative;-->
<!--      margin-left: 0;-->
<!--      flex-shrink: 0;-->
<!--      .local-stream-content {-->
<!--        width: 100%;-->
<!--        height: 100%;-->
<!--      }-->
<!--      .local-stream-control {-->
<!--        width: 100%;-->
<!--        height: 30px;-->
<!--        position: absolute;-->
<!--        bottom: 0;-->
<!--        background-color: rgba(0, 0, 0, 0.3);-->
<!--        display: flex;-->
<!--        justify-content: flex-end;-->
<!--        align-items: center;-->
<!--        padding: 0 10px;-->
<!--        .control {-->
<!--          margin-left: 10px;-->
<!--        }-->
<!--        .icon-class {-->
<!--          color: #fff;-->
<!--          cursor: pointer;-->
<!--          width: 20px;-->
<!--          height: 20px;-->
<!--        }-->
<!--      }-->
<!--    }-->
<!--  }-->

<!--  .info-container {-->
<!--    width: 100%;-->
<!--    display: flex;-->
<!--    justify-content: space-between;-->
<!--    .log-container {-->
<!--      flex-grow: 1;-->
<!--      border: 1px solid #dddddd;-->
<!--      height: 360px;-->
<!--      padding: 10px;-->
<!--      margin-right: 16px;-->
<!--      overflow-y: scroll;-->
<!--      .log-label {-->
<!--        margin: 0 0 6px;-->
<!--        font-weight: bold;-->
<!--      }-->
<!--      .log-state {-->
<!--        display: inline-block;-->
<!--        margin-right: 6px;-->
<!--      }-->
<!--      > div {-->
<!--        font-size: 12px;-->
<!--      }-->
<!--    }-->
<!--    .local-stream-container {-->
<!--      width: 100%;-->
<!--      height: 40%;-->
<!--      position: relative;-->
<!--      margin-left: 0;-->
<!--      flex-shrink: 0;-->
<!--      .local-stream-content {-->
<!--        width: 100%;-->
<!--        height: 100%;-->
<!--      }-->
<!--      .local-stream-control {-->
<!--        width: 100%;-->
<!--        height: 30px;-->
<!--        position: absolute;-->
<!--        bottom: 0;-->
<!--        background-color: rgba(0, 0, 0, 0.3);-->
<!--        display: flex;-->
<!--        justify-content: flex-end;-->
<!--        align-items: center;-->
<!--        padding: 0 10px;-->
<!--        .control {-->
<!--          margin-left: 10px;-->
<!--        }-->
<!--        .icon-class {-->
<!--          color: #fff;-->
<!--          cursor: pointer;-->
<!--          width: 20px;-->
<!--          height: 20px;-->
<!--        }-->
<!--      }-->
<!--    }-->
<!--  }-->

<!--  .info-container-mobile {-->
<!--    display: block;-->
<!--    .log-container {-->
<!--      margin-right: 0;-->
<!--    }-->
<!--    .local-stream-container {-->
<!--      width: 100%;-->
<!--      height: 100%;-->
<!--      margin-top: 10px;-->
<!--    }-->
<!--  }-->

<!--  .remote-container {-->
<!--    width: 100%;-->
<!--    margin-top: 0;-->
<!--    position: relative;-->
<!--    .remote-stream-container {-->
<!--      width: 100%;-->
<!--      height: 100%;-->
<!--      .remote-stream-control {-->
<!--        width: 100%;-->
<!--        height: 30px;-->
<!--        position: absolute;-->
<!--        bottom: 0;-->
<!--        background-color: rgba(0, 0, 0, 0.3);-->
<!--        display: flex;-->
<!--        justify-content: flex-end;-->
<!--        align-items: center;-->
<!--        padding: 0 10px;-->
<!--        .control {-->
<!--          margin-left: 10px;-->
<!--        }-->
<!--        .icon-class {-->
<!--          color: #fff;-->
<!--          cursor: pointer;-->
<!--          width: 20px;-->
<!--          height: 20px;-->
<!--        }-->
<!--      }-->
<!--    }-->
<!--  }-->
<!--}-->
<!--</style>-->


<style lang="scss" scoped>
.custom-dialog {
  text-align: center;
}
.custom-dialog .el-dialog {
  margin: 0 auto; /* 水平居中 */
}

.rtc-container {
  position: relative;

  .control-container {
    position: relative;
    margin-bottom: 1px;
    height: 40px;

    .left-control-container {
      position: absolute;
      left: 5px;
      top: 0;
      display: flex;
      align-items: center;
      height: 100%;
    }

    .right-control-container {
      position: absolute;
      right: 5px;
      top: 0;
      display: flex;
      align-items: center;
      height: 100%;
    }
  }

  .NewContainer {
    margin-top: 5px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .log-container {
      flex-grow: 1;
      border: 1px solid #dddddd;
      height: 360px;
      padding: 10px;
      margin-right: 16px;
      overflow-y: scroll;
      .log-label {
        margin: 0 0 6px;
        font-weight: bold;
      }
      .log-state {
        display: inline-block;
        margin-right: 6px;
      }
      > div {
        font-size: 12px;
      }
    }
    .local-stream-container {
      width: 100%;
      height: 100%;
      position: relative;
      margin-left: 0;
      flex-shrink: 0;
      .local-stream-content {
        width: 100%;
        height: 100%;
      }
      .local-stream-control {
        width: 100%;
        height: 30px;
        position: absolute;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0 10px;
        .control {
          margin-left: 10px;
        }
        .icon-class {
          color: #fff;
          cursor: pointer;
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .invite-link-container {
    width: 95%;
    color: #084298;
    background-color: #cfe2ff;
    position: relative;
    padding: 10px 16px;
    margin-bottom: 10px;
    margin-top: 10px;
    border: 1px solid #b6d4fe;
    border-radius: 0.25rem;
    display: none;

    .invite-input {
      margin-top: 10px;
    }

    .invite-btn {
      display: flex;
      cursor: pointer;
    }
  }

  .HostContainer {
    margin-top: 5px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .remote-container {
      width: 90%;
      height: auto;
      margin: 0 auto;
      position: relative;

      .remote-stream-container {
        width: 100%;
        height: 100%;
        position: relative;

        .remote-stream-control {
          width: 100%;
          height: 30px;
          position: absolute;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.3);
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding: 0 10px;

          .control {
            margin-left: 10px;
          }

          .icon-class {
            color: #fff;
            cursor: pointer;
            width: 20px;
            height: 20px;
          }
        }
      }

      .local-stream-container {
        width: 30%;
        height: auto;
        position: absolute;
        bottom: 10px;
        right: 10px;
        z-index: 1;

        .local-stream-content {
          width: 100%;
          height: 100%;
        }

        .local-stream-control {
          width: 100%;
          height: 30px;
          position: absolute;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.3);
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding: 0 10px;

          .control {
            margin-left: 10px;
          }

          .icon-class {
            color: #fff;
            cursor: pointer;
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    .local-only-container {
      width: 90%;
      margin-top: 10px;
      margin-bottom: 10px;

      .local-stream-container-alone {
        width: 100%;

        .local-stream-content {
          width: 100%;
          height: 100%;
        }

        .local-stream-control {
          width: 100%;
          height: 30px;
          position: absolute;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.3);
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding: 0 10px;

          .control {
            margin-left: 10px;
          }

          .icon-class {
            color: #fff;
            cursor: pointer;
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}
</style>